<template>
    <swiper ref="mySwiper" class="banner" :options="swiperOptions">
        <swiper-slide v-for="item in list" :key="item.id">
            <a v-if="item.link" class="swiper-link" :href="item.link">
                <div class="img-box" :style="{ backgroundImage: `url(${item.img})` }" />
            </a>
            <router-link v-else class="swiper-link" :to="bannerUrl(item)">
                <div class="img-box" :style="{ backgroundImage: `url(${item.img})` }" />
            </router-link>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination" />
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { link } from 'fs'
export default {
    name: 'HomeBanner',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        list: {
            type: Array,
            required: true,
            default: null
        }
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                // autoplay: true, // 等同于以下设置
                loop: true,
                loopAdditionalSlides: 2,
                autoplay: {
                    delay: 10000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
                // Some Swiper option/callback...
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    methods: {
        bannerUrl(item) {
            return item.informationid !== '0' ? `/news/detail?id=${item.informationid}` : `/product/detail?commodityid=${item.commodityid}`
        }
    }
}
</script>
<style lang="scss" scoped>
.banner {
    height: 600px;
}
.swiper-link {
    display: block;
    height: 100%;
    text-align: center;
    background-color: #eceff8;
    // > img {
    //     display: block;
    //     width: auto;
    //     height: 100%;
    //     margin: 0 auto;
    // }
}
.img-box {
    display: block;
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
