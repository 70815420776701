<template>
    <div class="layout layout-info">
        <section class="info-head">
            <h3 class="info-title">资讯</h3>
        </section>
        <section class="info-body">
            <info-list :info-list="list" />
            <section class="info-more">
                <router-link class="more-btn" to="/news">更多资讯一览</router-link>
            </section>
        </section>
    </div>
</template>
<script>
import infoList from '@c/infoList.vue'
export default {
    name: 'LayoutInfo',
    components: {
        infoList
    },
    props: {
        list: {
            type: Array,
            required: true,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
    .layout-info {
        padding-top: 20px;
        width: auto;
        background-color: $white;
    }
    .info-head {
        margin: 0 auto 20px;
        width: 1200px;
        border-bottom: 1px solid #e5e9f2;
    }
    .info-title {
        padding: 20px 0 20px 20px;
        position: relative;
        font-size: 18px;
        &::before {
            content: ' ';
            position: absolute;
            height: 24px;
            width: 2px;
            left: 0;
            top: 18px;
            background-color: $red;
        }
    }
    .info-body {
        width: 1200px;
        margin: 0 auto;
    }
    .info-more {
        padding: 20px 20px 40px;
        text-align: center;
    }
</style>
