<template>
    <div class="layout layout-prod">
        <section class="layout-prod-head">
            <h3 class="layout-prod-title">最新产品</h3>
        </section>
        <section class="layout-prod-body">
            <div class="swiper-container">
                <swiper ref="mySwiper" class="banner" :options="swiperOptions">
                    <swiper-slide v-for="(arr, index) in swiperList" :key="index" class="slide-prod" :style="{ width: `${(285+15)*arr.length}px` }">
                        <div v-for="item in arr" :key="item.commodityid" class="prod-item">
                            <router-link class="prod-item-link" :to="`/product/detail?commodityid=${item.commodityid}`">
                                <img class="prod-item-img" :src="item.img" alt="item.title">
                                <span class="prod-item-name">{{ item.title }}</span>
                                <span class="prod-item-more">产品详情</span>
                            </router-link>
                            <span class="prod-item-date">
                                <b class="prod-item-date-txt">
                                    {{ $utils.getPublishString(item.publishtype, item.publishyear, item.publishtime).replace('年', '年 ') }}
                                </b>
                            </span>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-scrollbar" />
            </div>
            <section class="prod-more">
                <router-link class="more-btn white" to="/product">更多产品一览</router-link>
            </section>
        </section>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'LayoutProd',
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        list: {
            type: Array,
            required: true,
            default: null
        }
    },
    data(vm) {
        return {
            swiperOptions: {
                slidesPerView: 'auto',
                // spaceBetween: 20,
                // centeredSlides: true,
                scrollbar: {
                    el: '.swiper-scrollbar'
                },
                // autoplay: true, // 等同于以下设置
                loop: vm.list.length > 4,
                autoplay: {
                    delay: 20000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
                // breakpoints: {
                //     1200: {
                //         slidesPerView: 2,
                //     },
                // }
                // Some Swiper option/callback...
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        },
        swiperList() {
            // 先分组(0-3, 4-7, 8-11, 12-15, 16-19)
            const arr = []
            const groupNum = Math.ceil(this.list.length / 4)
            console.log(groupNum)
            for (let i = 0; i < groupNum; i++) {
                arr.push(this.list.slice(4 * i, 4 * i + 4))
            }
            console.log(arr)
            return arr
        }
    }
}
</script>
<style lang="scss" scoped>
    .layout.layout-prod {
        width: auto;
        margin-bottom: 0;
        background: $red;
    }
    // .swiper-container {
    //     margin: 0 auto;
    //     width: 1200px;
    // }
    .layout-prod-head {
        padding: 40px 0 0;
    }
    .layout-prod-title {
        padding: 20px 0 20px 20px;
        position: relative;
        text-align: center;
        font-size: 28px;
        color: $white;
    }
    .prod-more {
        padding: 20px 0 40px;
        text-align: center;
    }
    .slide-prod {
        width: 1220px;
        padding-top: 120px;
    }
    .prod-item {
        position: relative;
        @include inlineBlock();
        @include widthHeight(285px, 375px);
        margin: 0 7px;
        padding: 10px;
        background-color: $white;
        border-radius: 10px;
    }
    .prod-item-img {
        width: 265px;
        height: 265px;
        object-fit: cover;
    }
    .prod-item-link {
        display: block;
        height: 100%;
        text-align: center;
    }
    .prod-item-name {
        // @include textEllipsisMulltiple();
        display: block;
        margin: 6px 0 12px;
        text-align: left;
        font-size: 14px;
        height: 40px;
        line-height: 1.4;
        overflow: hidden;
    }
    .prod-item-more {
        font-size: 14px;
        color: $red;
    }
    .prod-item-date {
        display: block;
        position: absolute;
        top: -120px;
        left: 50%;
        margin-left: -40px;
        width: 80px;
        height: 80px;
        padding: 10px;
        text-align: center;
        line-height: 1.5;
        font-size: 14px;
        border-radius: 50%;
        color: $red;
        background-color: $white;
        &::after {
            @include triangle('bottom', 10px, 14px, #fff);
            position: absolute;
            top: 90px;
            transform: translateX(-50%);
        }
    }
    .prod-item-date-txt {
        width: 57px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
