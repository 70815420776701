<template>
    <div class="layout layout-about">
        <section class="layout-about-head">
            <h3 class="layout-about-title">关于我们</h3>
        </section>
        <section class="layout-about-body">
            <ul class="about-list">
                <li class="about-item">
                    <img class="about-item-img" src="@a/img/about1.png" alt="">
                    <p class="about-item-txt">“kamisoul”为魔构科技旗下模型品牌，该品牌主要生产各类高端可动人偶系列，主要以C端产品为主要发展方向，致力于打造全球最高端的模型设计、制作、生产品牌。</p>
                </li>
                <li class="about-item">
                    <img class="about-item-img" src="@a/img/about2.png" alt="">
                    <p class="about-item-txt">“次元造物”和“MASTER ACTION FIGUARTS”（简称MAF）为“kamisoul”品牌下高端成品系列，意为将异次元作品在三次元中重新创造，在涂装上该系列主打细腻复杂的真实涂装风格，力求三次元中的真实还原。</p>
                </li>
                <li class="about-item">
                    <img class="about-item-img" src="@a/img/about3.png" alt="">
                    <p class="about-item-txt">“武动HIME”“武动HIME·改”“AP”“ARMOR PRINCESS”为“kamisoul”品牌下二次元高端成品系列，该系列以现代/幻想机械或军事为主题材，与二次元角色造型进行结合的原创系列，在涂装上该系列主打纯二次元风格。</p>
                </li>
            </ul>
        </section>
    </div>
</template>
<script>
export default {
    name: 'LayoutAbout',
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
    .layout.layout-about {
        width: auto;
        margin-bottom: 0;
        padding: 60px 0;
        background: #f4f4f4;
    }
    .layout-about-title {
        padding: 0 20px 20px;
        text-align: center;
        font-size: 28px;
    }
    .layout-about-body {
        text-align: center;
    }
    .about-item-img {
        display: block;
        margin: 40px auto 60px;
    }
    .about-item-txt {
        text-align: left;
        font-size: 14px;
        line-height: 1.5;
    }
    .about-item {
        @include inlineBlock();
        @include widthHeight(380px, 320px);
        margin: 0 10px;
        padding: 20px;
        font-size: 14px;
        background-color: $white;
        border-radius: 10px;
        &:last-child {
            .about-item-img {
                margin: 20px auto 40px;
            }
        }
    }
</style>
