
// @c an alias to /compontents
import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import homeBanner from './homeBanner.vue'
import layoutInfo from './layoutInfo.vue'
import layoutProd from './layoutProd.vue'
import layoutAbout from './layoutAbout.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { homeIndex } from '@api'
export default {
    name: 'Home',
    mixins: [ mixin, authMixin ],
    components: {
        pageHeader,
        pageFooter,
        homeBanner,
        layoutInfo,
        layoutProd,
        layoutAbout
    },
    data() {
        return {
            banner: [],
            commodity: [],
            information: []
        }
    },
    methods: {
        // 获取首页数据
        async getData() {
            const res = await homeIndex()
            // this.banners = res.list.list
            Object.keys(res.detail).forEach(key => {
                this[key] = res.detail[key]
            })
            // 如里>10,只取10个
            if (this.commodity.length > 10) this.commodity = this.commodity.slice(0, 10)
            console.log('this.commodity', this.commodity)
        }
    },
    created() {
        this.getAccount()
        this.getData()
    }
}
